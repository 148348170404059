(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("domainSettingsMarketplaceController", domainSettingsMarketplaceController);

    function domainSettingsMarketplaceController(
        $scope,
        $http,
        errorHandling,
        $rootScope,
        themesService,
        $translate,
        $mdDialog,
        userDataService,
        coreData,
        $window,
        $timeout
    ) {
        var vm = this;

        $scope.treeExpanded = true;
        vm.isInitialized = false;

        vm.marketplaceItems = [];
        vm.bannerImage = "";
        vm.loadMarketplaceSettings = loadMarketplaceSettings;
        vm.sortItems = sortItems;
        vm.openSettingsModal = openSettingsModal;
        vm.isOnlyOfficeEnabled = isOnlyOfficeEnabled;
        vm.domainAdminCanOverride = false;
        vm.lightordark;
        vm.configuredAccounts = [];
        vm.reloadvar = true;
        vm.accountTypes = [
            { name: "dropbox",    id: 2  },
            { name: "googledrive",id: 9  },
            { name: "leitzcloud", id: 7  },
            { name: "onedrive",   id: 4  },
            { name: "owncloud",   id: 10 },
            { name: "vboxx",      id: 8  }
        ];

        vm.marketplaceSideMenuItems = [
            { title: $translate.instant('OVERVIEW'), sref: 'index.domain-marketplace.overview' }
        ];

        activate();

        async function activate() {
            vm.marketplaceItems = [];
            await coreData.init;
            $rootScope.$on("themeService:changed", setBannerImage);
            vm.enableOnlyOffice = await vm.isOnlyOfficeEnabled();
            vm.enablewopi       = await isCollaboraOnlineEnabled();
            setBannerImage();
            UpdateConnectedServices();
            loadMarketplaceSettings();
            sortItems();
            vm.isInitialized = true;
            $scope.$applyAsync();
            UpdateConnectedServices();
            console.log(vm.configuredAccounts);

        }

        function UpdateConnectedServices() {
            vm.configuredAccounts = [];
            return $http.get('/api/v1/settings/connected-services')
                .then(function (response) {
                    if (response.data.connectedService) {
                        const services = response.data.connectedService;
                        for (let i = 0; i < services.length; i++) {
                            for (let j = 0; j < vm.accountTypes.length; j++) {
                                if (services[i].type === vm.accountTypes[j].id) {
                                    vm.configuredAccounts.push(vm.accountTypes[j].name);
                                }
                            }
                        }
                    }
                    vm.configuredAccounts = [...new Set(vm.configuredAccounts)];
                });
        }

        function setBannerImage() {
            if (themesService.isThemeDark()) {
                vm.bannerImage = "img/MarketPlace/marketplaceBanner_dark_1570x200.webp";
                vm.lightordark = "dark";
            } else {
                vm.bannerImage = "img/MarketPlace/marketplaceBanner_light_1570x200.webp";
                vm.lightordark = "light";
            }
        }

        // Check if OnlyOffice is enabled
        async function isOnlyOfficeEnabled() {
            var apiUrl = "/api/v1/marketplace/IsOnlyOfficeEnabled";
            return $http.get(apiUrl)
                .then(response => response.data)
                .catch(error => {
                    console.error("Failed to fetch OnlyOffice settings", error);
                    return false;
                });
        }

        // Check if Collabora is enabled
        async function isCollaboraOnlineEnabled() {
            var apiUrl = "/api/v1/marketplace/IsCollaboraOnlineEnabled";
            return $http.get(apiUrl)
                .then(response => response.data)
                .catch(error => {
                    console.error("Failed to fetch Collabora settings", error);
                    return false;
                });
        }

        // Load marketplace settings from the backend and build the list dynamically
        function loadMarketplaceSettings() {
            $rootScope.spinner = $rootScope.spinner || { show: angular.noop, hide: angular.noop };
            $rootScope.spinner.show();

            return $http.get("/api/v1/marketplace/getmarketplace")
                .then(function (response) {
                    var marketplaceSettings = response.data || {};
                    vm.domainAdminCanOverride = marketplaceSettings.domainAdminCanOverride || false;

                    if (!coreData.user.isDomainAdmin){
                        vm.domainAdminCanOverride = false;
                    }

                    // If Collabora / OnlyOffice is enabled, ensure they're in configuredAccounts
                    if (vm.enablewopi){
                        vm.configuredAccounts.push("collabora");
                    }
                    if (vm.enableOnlyOffice){
                        vm.configuredAccounts.push("onlyoffice");
                    }

                    vm.marketplaceItems = [];

                    Object.entries(marketplaceSettings).forEach(([key, value]) => {
                        if (key.endsWith("Enabled")) {
                            var itemId = key.replace("Enabled", "");
                            var parsedName = itemId.toUpperCase();
                            var currentEnabledState = false;

                            if (itemId.toLowerCase() === "onlyoffice") {
                                currentEnabledState = vm.enableOnlyOffice;
                            } else if (itemId.toLowerCase() === "collabora") {
                                currentEnabledState = vm.enablewopi;
                            } else {
                                currentEnabledState = !!value;
                            }

                            var imageUrl = `img/MarketPlace/icon_${itemId.toLowerCase()}.webp`;
                            var name = $translate.instant(parsedName) || parsedName;
                            var description = $translate.instant(parsedName + "_DESCRIPTION") || "";

                            vm.marketplaceItems.push({
                                id: itemId,
                                name: name,
                                description: description,
                                imageUrl: imageUrl,
                                allowed: !!value,
                                enabled: currentEnabledState
                            });
                        }
                    });
                })
                .catch(errorHandling.report)
                .finally($rootScope.spinner.hide);
        }

        function sortItems() {
            vm.marketplaceItems.sort((a, b) => a.name.localeCompare(b.name));
        }

        function openSettingsModal(item) {
            // Distinguish cloud storage from modular config
            if (["googledrive", "dropbox", "onedrive", "leitzcloud", "vboxx"].includes(item.id.toLowerCase())) {
                // Cloud Storage Connection modal
                $mdDialog.show({
                    controller: "userCloudStorageConnectController",
                    controllerAs: "cloudCtrl",
                    templateUrl: "app/marketplace/modals/user-cloud-storage-connect.modal.html",
                    clickOutsideToClose: true,
                    locals: {
                        providerName: item.name,
                        providerId: item.id,
                        enabled: item.enabled,
                        alreadyConfigured: vm.configuredAccounts.includes(item.id),
                        DomainAdminCanOverride: vm.domainAdminCanOverride
                    }
                })
                    .then(function() {
                        // This block runs only if the modal calls $mdDialog.hide()
                        $rootScope.spinner.show();
                        UpdateConnectedServices()
                            .then(loadMarketplaceSettings)
                            .then(function() {
                                sortItems();
                                // Force an immediate UI update
                                $scope.$applyAsync();
                            })
                            .catch(errorHandling.report)
                            .finally($rootScope.spinner.hide);
                    })
                    .catch(function() {
                        debugger;
                        // If the modal calls $mdDialog.cancel() or the user closes it manually
                        console.log(`Settings modal dismissed/canceled for addon: ${item.id}`);
                        $rootScope.spinner.show();
                        UpdateConnectedServices()
                            .then(loadMarketplaceSettings)
                            .then(function() {
                                sortItems();
                                // Force an immediate UI update
                                $scope.$applyAsync();
                            })
                            .catch(errorHandling.report)
                            .finally($rootScope.spinner.hide);
                    });

            } else {
                // Modular configuration modal
                $mdDialog.show({
                    controller: "modularConfigurationModalController",
                    controllerAs: "settingsCtrl",
                    templateUrl: "app/marketplace/modals/modular-configuration.modal.html",
                    clickOutsideToClose: true,
                    locals: {
                        addonName: item.id,
                        enabled: item.enabled,
                        alreadyConfigured: vm.configuredAccounts.includes(item.id),
                        domainAdminCanOverride: vm.domainAdminCanOverride
                    }
                })
                    .then(function (updatedSettings) {
                        if (!updatedSettings) return;
                        $rootScope.spinner.show();

                        // Decide the API URL & payload
                        var apiUrl, payload;
                        switch (item.id.toLowerCase()) {
                            case "owncloud":
                                if (updatedSettings) {
                                    apiUrl = "/api/v1/settings/owncloud-put";
                                    payload = updatedSettings;
                                }
                                break;
                        }
                        if (!apiUrl || !payload) {
                            console.warn(`No valid settings to save for addon: ${item.id}`);
                            $rootScope.spinner.hide();
                            return;
                        }

                        // Save settings
                        $http.post(apiUrl, payload)
                            .then(function () {
                                if (typeof payload.enabled !== "undefined") {
                                    item.enabled = payload.enabled;
                                }
                                // After saving, re-fetch everything
                                return UpdateConnectedServices();
                            })
                            .then(loadMarketplaceSettings)
                            .then(function() {
                                sortItems();
                                $scope.$applyAsync();
                            })
                            .catch(function (error) {
                                console.error(`Failed to save settings for addon: ${item.id}`, error);
                                errorHandling.report(error);
                            })
                            .finally($rootScope.spinner.hide);
                    })
                    .catch(function () {
                        console.log(`Settings modal dismissed/canceled for addon: ${item.id}`);
                    });
            }
        }
    }
})();
