(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("adminConfigurationModuleController", adminConfigurationModuleController);

    function adminConfigurationModuleController(
        $mdDialog,
        $http,
        themesService,
        $translate,
        errorHandling,
        localAddonName,
        localAddonId,
        localEnabled,
        localAlreadyConfigured,
        localDomainAdminCanOverride
    ) {
        var vm = this;

        // Assign injected locals to our view-model
        activate();
        vm.addonName = localAddonName;
        vm.addonId = localAddonId;
        vm.addonEnabled = localEnabled;
        vm.alreadyConfigured = localAlreadyConfigured;
        vm.domainAdminCanOverride = localDomainAdminCanOverride;
        vm.setBannerImage = setBannerImage;
        
        vm.bannerImage = "";
        function activate() {
            vm.addonName = localAddonName;
            vm.addonId = localAddonId;
            vm.addonEnabled = localEnabled;
            vm.alreadyConfigured = localAlreadyConfigured;
            vm.domainAdminCanOverride = localDomainAdminCanOverride;
            setBannerImage();
        }

        // Container for dynamic sections & settings
        vm.activeSections = [];
        function setBannerImage() {
            var defaultImage = "img/MarketPlace/default_banner.webp";
            var customImage = `img/MarketPlace/${vm.addonName}_banner.webp`;

            $http.head(customImage)
                .then(() => {
                    vm.bannerImage = customImage;
                })
                .catch(() => {
                    vm.bannerImage = defaultImage;
                });
        }

        // Initialize possible settings for the chosen addon
        function initializeSettings(name) {
            name = name.toString();
            switch (name.toLowerCase()) {
                case "collabora":
                    vm.activeSections = [
                        {
                            title: $translate.instant(name.toUpperCase()),
                            settings: [
                                {
                                    key: "serverUrl",
                                    type: "input",
                                    label: $translate.instant("COLLABORA_SERVER_URL"),
                                    value: ""
                                },
                                {
                                    key: "maxConnections",
                                    type: "input",
                                    label: $translate.instant("MAX_CONNECTIONS_ZERO_UNLIMITED"),
                                    value: 0
                                },
                                {
                                    key: "maxConnectionsPerUser",
                                    type: "input",
                                    label: $translate.instant("MAX_CONNECTIONS_PER_USER_ZERO_UNLIMITED"),
                                    value: 0
                                }
                            ]
                        }
                    ];
                    fetchSettings();
                    break;

                case "onlyoffice":
                    vm.activeSections = [
                        {
                            title: $translate.instant(name.toUpperCase()),
                            settings: [
                                {
                                    key: "serverUrl",
                                    type: "input",
                                    label: $translate.instant("ONLYOFFICE_SERVER_URL"),
                                    value: ""
                                },
                                {
                                    key: "maxConnections",
                                    type: "input",
                                    label: $translate.instant("MAX_CONNECTIONS_ZERO_UNLIMITED"),
                                    value: 0
                                },
                                {
                                    key: "maxConnectionsPerUser",
                                    type: "input",
                                    label: $translate.instant("MAX_CONNECTIONS_PER_USER_ZERO_UNLIMITED"),
                                    value: 0
                                }
                            ]
                        }
                    ];
                    fetchSettings();
                    break;

                default:
                    // By default, no specific fields to populate
                    vm.activeSections = [
                        {
                            title: "",
                            settings: []
                        }
                    ];
                    break;
            }
        }

        function fetchSettings() {
            var apiUrl;
            switch (vm.addonName.toLowerCase()) {
                case "collabora":
                    apiUrl = "/api/v1/marketplace/GetCollaboraOnlineSettings";
                    break;
                case "onlyoffice":
                    apiUrl = "/api/v1/marketplace/GetOnlyOfficeSettings";
                    break;
                default:
                    console.warn(`No settings endpoint defined for addon: ${vm.addonName}`);
                    return;
            }

            $http.post(apiUrl, {})
                .then(function (response) {
                    var settings = response.data;
                    vm.addonEnabled = settings.enabled || false;
                    populateSettings(settings);
                })
                .catch(function (error) {
                    console.error(`Failed to fetch settings for addon: ${vm.addonName}`, error);
                    vm.errorMessage = $translate.instant("FETCH_SETTINGS_FAILED");
                });
        }

        function populateSettings(settings) {
            vm.activeSections.forEach(section => {
                section.settings.forEach(setting => {
                    if (setting.key in settings) {
                        setting.value = settings[setting.key] !== undefined ? settings[setting.key] : "";
                    }
                });
            });
        }
        

        // Close the modal
        vm.cancel = function () {
            $mdDialog.cancel();
        };

        // Save and send updated settings to the parent context
        vm.save = function () {
            var updatedSettings = {};

            // OnlyOffice scenario
            if (vm.addonName.toLowerCase() === "onlyoffice") {
                updatedSettings = { enabled: vm.addonEnabled };
                vm.activeSections.forEach(section => {
                    section.settings.forEach(setting => {
                        updatedSettings[setting.key] = setting.value;
                    });
                });

                $http.post("/api/v1/marketplace/setOnlyOfficeSettings", updatedSettings)
                    .then(response => {
                        $mdDialog.hide(response.data);
                    })
                    .catch(error => {
                        errorHandling.handleError(error);
                    });
            }

            // Collabora scenario
            if (vm.addonName.toLowerCase() === "collabora") {
                updatedSettings = { enabled: vm.addonEnabled };
                vm.activeSections.forEach(section => {
                    section.settings.forEach(setting => {
                        updatedSettings[setting.key] = setting.value;
                    });
                });

                $http.post("/api/v1/marketplace/setCollaboraSettings", updatedSettings)
                    .then(response => {
                        $mdDialog.hide(response.data);
                    })
                    .catch(error => {
                        errorHandling.handleError(error);
                    });
            }

            // For any other cloud provider scenario
            if (vm.addonName.toLowerCase() !== "collabora" && vm.addonName.toLowerCase() !== "onlyoffice") {
                vm.toggleCloudProvider(vm.addonId, vm.addonEnabled);
            }
        };

        // Toggle Cloud Storage Provider
        vm.toggleCloudProvider = function (providerKey, enable) {
            var request = {
                ProviderKey: providerKey,
                Enable: enable
            };

            $http.post("/api/v1/marketplace/ToggleCloudStorageProvider", request)
                .then(response => {
                    console.log(`${providerKey} toggled to: ${enable}`);
                    vm.cancel();
                })
                .catch(error => {
                    errorHandling.handleError(error);
                });
        };

        // Called when a custom storage directive signals success
        vm.handleStorageSuccess = function (result) {
            console.log("Storage configuration updated successfully:", result);
        };

        // Initialize once the controller loads
        initializeSettings(vm.addonName);
    }
})();